import {createContext, useEffect, useState} from "react";
import {STORAGE} from "../util/localStorage";
import {useTranslation} from "../util/localization";
import {SUPPORTED_CURRENCIES_FORMATTERS, SUPPORTED_LANGUAGES} from "../util/constants";

export const LanguageContext = createContext();

export const LanguageProvider = ({children}) => {
    const [language, setLanguage] = useState('');
    const [currencyFormat, setCurrencyFormat] = useState('');
    const {t} = useTranslation(language);

    /*
        create an object wich contains the neccessary information to format numbers into currency and is created only once, using this approach instead of
        just calling .toLocaleString allow us to avoid performing the search that .toLocaleString performs in every call
        more info: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
    */
    const currencyTemplate =
        currencyFormat === SUPPORTED_CURRENCIES_FORMATTERS.USA
            ? new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            })
            : new Intl.NumberFormat('es-UY', {
                style: 'currency',
                currency: 'USD'
            })

    const formatCurrency = (value) => {
        if (value !== undefined && value !== null) {
            return currencyTemplate.format(value)
        } else {
            return "N/A"
        }
    }

    useEffect(() => {
        setLanguage(STORAGE.get(STORAGE.LANGUAGE) || SUPPORTED_LANGUAGES.SPANISH);
        setCurrencyFormat(STORAGE.get(STORAGE.CURRENCY_FORMATTER) || SUPPORTED_CURRENCIES_FORMATTERS.LOCALE)
    }, []);

    const provider = {
        language,
        setLanguage: (lang) => {
            STORAGE.set(STORAGE.LANGUAGE, lang);
            setLanguage(lang);
        },
        t,
        languageOptions: [
            {value: SUPPORTED_LANGUAGES.ENGLISH, label: 'EN - English'},
            {value: SUPPORTED_LANGUAGES.SPANISH, label: 'ES - Spanish'},
            {value: SUPPORTED_LANGUAGES.PORTUGUESE, label: 'PT - Portuguese'}
        ],
        currencyFormat,
        setCurrencyFormat: (formatter) => {
            STORAGE.set(STORAGE.CURRENCY_FORMATTER, formatter);
            setCurrencyFormat(formatter);
        },
        currencyFormatOptions: [
            {value: SUPPORTED_CURRENCIES_FORMATTERS.USA, label: 'Formato estadounidense ej: (1,000,000.00)'},
            {value: SUPPORTED_CURRENCIES_FORMATTERS.LOCALE, label: 'Formato local ej: (US$ 1.000.000,00)'},
        ],
        formatCurrency,
    };
    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );
}